import { MiraCustomerBlocking } from "./customer";
import {
  ApprovalRequestState,
  CustomerType,
  DataTableQueryFnParams,
  IndustryName,
  ItemType,
  Language,
  Nullable,
  PartialBy,
  PdfQuality,
  PriceCategory,
  PricingBasis,
  SelectSheetOption,
  TableFilterOptions,
  TableWithPaginationDataResponse,
} from "./index";

export enum OffersTableTabState {
  Sent = "1",
  Incomplete = "2",
  Expired = "3",
  Archived = "4",
}

export enum OfferState {
  Draft = "draft",
  InternalApproval = "internalApproval",
  InternalRejection = "internalRejection",
  ReadyForSending = "readyForSending",
  Sent = "sent",
  Approved = "approved",
  Rejected = "rejected",
  RequiresChanges = "requiresChanges",
  Expired = "expired",
  AwaitingOrder = "awaitingOrder",
  PartiallyOrdered = "partiallyOrdered",
  Ordered = "ordered",
}

export enum OfferStep {
  Details,
  Items,
  ItemDetails,
  Pricing,
  Summary,
}

export enum OfferRejectionReason {
  Price = "Hinta liian kallis",
  Product = "Tarjottavat tuotteet",
  Service = "Palvelu ei riittävä",
  Need = "Tarve peruuntunut",
}

export enum OfferEmailType {
  ApprovalRequest = "approvalRequest",
  Approved = "approved",
  Discarded = "discarded",
}

export type OfferWarningCounts = {
  belowOneCoefficient: number;
  below1Point2Coefficient: number;
  below70: number;
  belowCritical: number;
  zeroPrice: number;
};

export type OfferRejectionReasonKeys = keyof typeof OfferRejectionReason;
export type CompareType = "contract" | "comparison";

export type OfferRouterParams = {
  id: string;
  approvalId?: string;
};

export type OfferComparisonItem = {
  dayPrice: number | null;
  monthPrice: number | null;
};

export type OfferComparisonItems = Record<string, OfferComparisonItem>;

export type OfferCompare = {
  id?: number;
  name: string;
  selectedOption?: SelectSheetOption;
  items?: OfferComparisonItems | null;
};

export type OfferCompareState = {
  [Key in `${CompareType}Sheet`]: OfferCompare;
};

export type OfferDateFieldKeys =
  | "offerLeasePeriodStart"
  | "offerLeasePeriodEnd"
  | "offerValidityStart"
  | "offerValidityEnd";

export type OfferItemCommentFieldKeys = keyof Pick<
  DatabaseOfferItem,
  "approvalApproverComment" | "comment"
>;

export type OfferDateFields<T extends string | Date = string> = {
  [Key in OfferDateFieldKeys]: T;
};

export type OfferRevision = Pick<DatabaseOffer, "id" | "removedAt" | "state">;

export type DatabaseOffer = OfferDateFields & {
  approvalApproverComment?: string | null;
  approvalCreatorComment?: string;
  archivedAt: Date | null;
  createdAt: Date;
  customerId?: string;
  customerName: string;
  customerRejectionComment?: string | null;
  customerRejectionReason?: OfferRejectionReasonKeys | null;
  customerType: CustomerType;
  id: number;
  industry: IndustryName;
  name: string;
  pdfCustomerName: string;
  priceCategory: PriceCategory;
  pricingBasis: PricingBasis;
  removedAt?: Date;
  revisionOf?: number | null;
  state: OfferState;
  previousState?: OfferState | null;
  updatedAt: Date;
  userEmail: string;
  userFirstName?: string;
  userFullName?: string;
  userId: number;
  userLastName?: string;
  userPhoneNumber: string;
  worksite?: string;
  worksiteStreetAddress?: string;
  worksiteZipCode?: string;
  worksiteCity?: string;
  receiverName?: string;
  receiverEmail?: string;
  receiverPhone?: string;
  sellerName?: string;
  sellerEmail?: string;
  sellerPhoneNumber?: string;
  deliveryAddressStreet?: string;
  deliveryAddressZipCode?: string;
  deliveryAddressCity?: string;
  responsibleInventSiteName?: string;
  responsibleInventSiteStreetAddress?: string;
  responsibleInventSiteZipCode?: string;
  responsibleInventSiteCity?: string;
  responsibleInventSitePhone?: string;
  includeRamiturva: boolean;
  language: Language;
  referenceComment?: string;
  totalCoefficient?: number;
  totalPrice?: number;
  totalSalesPrice?: number;
  totalRentalPrice?: number;
  dealProbability?: number;
  totalRamiturvaPrice?: number;
  // the following fields don't exist in the table,
  // they are included in the sequelize transactions
  original?: OfferRevision | null;
  revision?: OfferRevision | null;
};

export type DatabaseOfferWithItems = DatabaseOffer & {
  items: DatabaseOfferItem[];
};

export type DatabaseOfferWithApprovalRequest = DatabaseOffer &
  MiraCustomerBlocking & {
    approvalRequests: DatabaseOfferApprovalRequestWithOffer[];
  };

export type NewDatabaseOffer = Omit<
  DatabaseOffer,
  "id" | "createdAt" | "updatedAt" | OfferDateFieldKeys
> &
  OfferDateFields<Date>;

export type ClientOffer = OfferDateFields<Date> &
  Pick<
    DatabaseOfferWithItems,
    | "approvalApproverComment"
    | "archivedAt"
    | "customerId"
    | "customerRejectionComment"
    | "customerRejectionReason"
    | "customerType"
    | "items"
    | "pdfCustomerName"
    | "priceCategory"
    | "pricingBasis"
    | "removedAt"
    | "state"
    | "worksite"
    | "worksiteStreetAddress"
    | "worksiteZipCode"
    | "worksiteCity"
    | "receiverName"
    | "receiverEmail"
    | "receiverPhone"
    | "deliveryAddressStreet"
    | "deliveryAddressZipCode"
    | "deliveryAddressCity"
    | "responsibleInventSiteName"
    | "responsibleInventSiteStreetAddress"
    | "responsibleInventSiteZipCode"
    | "responsibleInventSiteCity"
    | "responsibleInventSitePhone"
    | "includeRamiturva"
    | "referenceComment"
    | "totalCoefficient"
    | "totalPrice"
    | "totalSalesPrice"
    | "totalRentalPrice"
    | "language"
    | "dealProbability"
    | "sellerName"
    | "sellerEmail"
    | "sellerPhoneNumber"
  > & {
    customerName?: string;
    id?: number;
    industry?: IndustryName;
    name?: string;
  };

export type ValidatedClientOffer = PartialBy<Required<ClientOffer>, "id">;

export type DatabaseOfferItemDetails = {
  miraName?: Nullable<string>;
  miraItemNumber?: string;
  miraItemType?: ItemType;
};

export type DatabaseOfferItem = {
  id: number;
  offerSheetId: number;
  itemId: number | null;
  dayPrice: Nullable<number>;
  proposalDayPrice: Nullable<number>;
  monthPrice: Nullable<number>;
  proposalMonthPrice: Nullable<number>;
  quantity: number;
  leasePeriodStart: Date;
  leasePeriodEnd: Date;
  comment: string | null;
  pricingBasis: PricingBasis;
  approvalApproverComment?: Nullable<string>;
  details: DatabaseOfferItemDetails | null;
  unit: string;
  includeRamiturva?: boolean;
  ramiturvaDayPrice?: number | null;
  ramiturvaMonthPrice?: number | null;
  ramiturvaProposalDayPrice?: number | null;
  ramiturvaProposalMonthPrice?: number | null;
  ramiturvaName?: string | null;
};

export type UpdatedDatabaseOfferItem = PartialBy<
  DatabaseOfferItem,
  "id" | "offerSheetId" | "itemId" | "comment" | "details"
>;

export type DatabaseOfferApprovalRequestOfferSheetKeys =
  | "customerName"
  | "name"
  | "worksite";

export type DatabaseOfferApprovalRequest = {
  id: number;
  offerSheetId: number;
  state: ApprovalRequestState;
  creatorEmail: string;
  approverEmail: string;
  lockedReason: string | null;
  lockedBy: string | null;
  deprecated: boolean;
  previousRequests?: Omit<DatabaseOfferApprovalRequest, "previousRequests">[];
  offerSheet?: Pick<DatabaseOffer, DatabaseOfferApprovalRequestOfferSheetKeys>;
  createdAt: Date;
  updatedAt: Date;
};

export type DatabaseOfferApprovalRequestWithOffer = Omit<
  DatabaseOfferApprovalRequest,
  "offerSheet"
> & {
  [Key in DatabaseOfferApprovalRequestOfferSheetKeys as `offerSheet.${Key}`]: DatabaseOffer[Key];
};

export type NewDatabaseOfferApprovalRequest = Pick<
  DatabaseOfferApprovalRequestWithOffer,
  "offerSheetId" | "creatorEmail" | "approverEmail"
>;

/* API ROUTE TYPES */
type RequestParamsWithId<Params = {}> = Params & {
  id: string;
};

// filter option types
export type GetOffersTableFilterOptions = TableFilterOptions<
  DatabaseOfferWithApprovalRequest,
  {
    archived?: boolean;
    byUser?: boolean;
    includeStates?: OfferState[];
    removed?: boolean;
  }
>;
export type GetOfferApprovalRequestsTableFilterOptions = TableFilterOptions<
  DatabaseOfferApprovalRequestWithOffer,
  {
    byUser?: boolean;
  }
>;

// create offer
export type CreateOfferRequestParams = Record<string, unknown>;
export type CreateOfferResponseBody = DatabaseOfferWithItems;
export type CreateOfferRequestBody = ValidatedClientOffer;

// get offers
export type GetOffersRequestParams = Record<string, unknown>;
export type GetOffersRequestQueryParams<FilterType = string> =
  DataTableQueryFnParams<DatabaseOffer, FilterType>;
export type GetOffersResponseBody =
  TableWithPaginationDataResponse<DatabaseOfferWithApprovalRequest>;

// get offer
export type GetOfferRequestParams = RequestParamsWithId;
export type GetOfferResponseBody = DatabaseOfferWithItems;
export type GetOfferRequestQueryParams = { readOnly?: boolean };

// update offer
export type UpdateOfferRequestParams = RequestParamsWithId;
export type UpdateOfferResponseBody = DatabaseOfferWithItems;
export type UpdateOfferRequestBody = Partial<ClientOffer>;

// create new revision
export type CreateRevisionRequestParams = RequestParamsWithId;
export type CreateRevisionResponseBody = DatabaseOfferWithItems;
export type CreateRevisionRequestBody = Partial<OfferDateFields<Date>>;

// update offer item
export type UpdateOfferItemRequestParams = RequestParamsWithId;
export type UpdateOfferItemRequestBody = Partial<UpdatedDatabaseOfferItem>;
export type UpdateOfferItemResponseBody = DatabaseOfferItem;

// create / update offer items
export type UpdateOfferItemsRequestParams = RequestParamsWithId;
export type UpdateOfferItemsResponseBody = DatabaseOfferItem[];
export type UpdateOfferItemsRequestBody = UpdatedDatabaseOfferItem[];

// delete offer items
export type DeleteOfferItemsRequestParams = Record<string, unknown>;
export type DeleteOfferItemsRequestQueryParams = {
  id: number[];
};
export type DeleteOfferItemsResponseBody = void;
export type DeleteOfferItemsRequestBody = undefined;

// create offer approval request
export type CreateOfferApprovalRequestParams = RequestParamsWithId;
export type CreateOfferApprovalResponseBody =
  DatabaseOfferApprovalRequestWithOffer;
export type CreateOfferApprovalRequestBody = Pick<
  DatabaseOfferApprovalRequest,
  "approverEmail"
> &
  Pick<DatabaseOffer, "approvalCreatorComment"> & {
    warningCounts: OfferWarningCounts;
  };

// get offer approval request
export type GetOfferApprovalRequestRequestParams = RequestParamsWithId;
export type GetOfferApprovalRequestResponseBody =
  DatabaseOfferApprovalRequestWithOffer;

// get offer approval requests
export type GetOfferApprovalRequestsRequestParams = Record<string, unknown>;
export type GetOfferApprovalRequestsRequestQueryParams<FilterType = string> =
  DataTableQueryFnParams<DatabaseOfferApprovalRequestWithOffer, FilterType>;
export type GetOfferApprovalRequestsResponseBody =
  TableWithPaginationDataResponse<DatabaseOfferApprovalRequestWithOffer>;

// update offer approval request
export type UpdateOfferApprovalRequestParams = RequestParamsWithId<{
  approvalId: string;
}>;
export type UpdateOfferApprovalRequestBody =
  Partial<DatabaseOfferApprovalRequest>;
export type UpdateOfferApprovalResponseBody =
  DatabaseOfferApprovalRequestWithOffer;

// resolve offer approval request
export type ResolveOfferApprovalRequestParams = RequestParamsWithId<{
  approvalId: string;
}>;
export type ResolveOfferApprovalResponseBody =
  DatabaseOfferApprovalRequestWithOffer;
export type ResolveOfferApprovalRequestBody = {
  action: ApprovalRequestState.Approved | ApprovalRequestState.Discarded;
} & Pick<DatabaseOffer, "approvalApproverComment">;

// download pdf
export type GetOfferPdfRequestParams = RequestParamsWithId;
export type GetOfferPdfRequestQueryParams = {
  draft?: boolean;
  itemsOnly?: boolean;
};
export type GetOfferPdfResponseBody = BufferSource;
